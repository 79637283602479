import {motion} from "motion/react"
import React from "react"
import type {ToastPropsExtended} from "~/components/uikit/Toast"
import styles from "~/components/uikit/Toast/index.module.css"

const DEFAULT_TIMEOUT = 3000

const SuccessPath = () => (
  <path
    fill="var(--status-online)"
    d="M21.7 5.3a1 1 0 0 1 0 1.4l-12 12a1 1 0 0 1-1.4 0l-6-6a1 1 0 1 1 1.4-1.4L9 16.58l11.3-11.3a1 1 0 0 1 1.4 0Z"
  />
)

const DangerPath = () => (
  <path
    fill="var(--status-danger)"
    d="M19.3 20.7a1 1 0 0 0 1.4-1.4L13.42 12l7.3-7.3a1 1 0 0 0-1.42-1.4L12 10.58l-7.3-7.3a1 1 0 0 0-1.4 1.42L10.58 12l-7.3 7.3a1 1 0 1 0 1.42 1.4L12 13.42l7.3 7.3Z"
  />
)

export const Toast = ({
  id,
  type,
  children,
  timeout = DEFAULT_TIMEOUT,
  onClick,
  onTimeout,
  onClose,
  closeToast,
}: ToastPropsExtended) => {
  React.useEffect(() => {
    const handleTimeout = () => {
      if (onTimeout) {
        onTimeout()
      } else {
        closeToast(id)
      }
    }

    if (timeout > 0) {
      const timer = setTimeout(handleTimeout, timeout)
      return () => clearTimeout(timer)
    }

    return
  }, [timeout, onTimeout, closeToast, id])

  React.useEffect(() => {
    return () => {
      if (onClose) {
        onClose()
      }
    }
  }, [onClose])

  const handlePopoutClick = React.useCallback(
    (event: React.SyntheticEvent) => {
      if (onClick) {
        onClick(event)
      } else {
        closeToast(id)
      }
    },
    [onClick, closeToast, id],
  )

  return (
    <motion.div
      onClick={handlePopoutClick}
      className={styles.toast}
      initial={{opacity: 0, y: -20}}
      animate={{opacity: 1, y: 0}}
      exit={{opacity: 0, y: 20}}
      transition={{duration: 0.3}}
    >
      <svg
        className={styles.icon}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
      >
        {type === "success" ? <SuccessPath /> : <DangerPath />}
      </svg>
      <div className={styles.text}>{children}</div>
    </motion.div>
  )
}
