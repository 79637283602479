import {autoUpdate, computePosition, flip, offset, shift} from "@floating-ui/react-dom"
import clsx from "clsx"
import useFocusLock from "focus-layers"
import React from "react"
import * as PopoutActionCreators from "~/actions/PopoutActionCreators"
import type {PopoutPosition} from "~/components/uikit/Popout"
import {useTransitionStyles} from "~/components/uikit/Popout/hooks/useTransitionStyles"
import popoutStyles from "~/components/uikit/Popout/index.module.css"
import ModalStore from "~/stores/ModalStore"
import PopoutStore from "~/stores/PopoutStore"

type PopoutItemProps = {
  popoutKey: string
  render: ({popoutKey, onClose}: {popoutKey: string; onClose: (event: Event) => void}) => React.ReactNode
  position: PopoutPosition
  animationType: "smooth" | "none"
  text?: string
  containerClass?: string
  zIndexBoost?: number
  shouldAutoUpdate?: boolean
  padding?: number
  offsetMainAxis?: number
  offsetCrossAxis?: number
  target: HTMLElement
  onCloseRequest?: (event?: Event) => boolean
  returnFocusRef?: React.RefObject<HTMLElement>
}

const PopoutItem = ({
  popoutKey,
  render,
  position = "top",
  animationType = "smooth",
  containerClass,
  zIndexBoost,
  shouldAutoUpdate = true,
  offsetMainAxis = 8,
  offsetCrossAxis = 0,
  target,
  onCloseRequest,
  returnFocusRef,
}: PopoutItemProps) => {
  const popoutRef = React.useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = React.useState(true)

  useFocusLock(popoutRef)

  React.useEffect(() => {
    if (!(target && popoutRef.current)) {
      return
    }

    const cleanup = autoUpdate(target, popoutRef.current, () => {
      if (!(target && popoutRef.current)) {
        return
      }

      computePosition(target, popoutRef.current!, {
        placement: position,
        middleware: [offset({mainAxis: offsetMainAxis, crossAxis: offsetCrossAxis}), flip(), shift({padding: 6})],
      }).then(({x, y}) => {
        if (popoutRef.current) {
          Object.assign(popoutRef.current?.style, {
            left: `${x}px`,
            top: `${y}px`,
          })
        }
      })
    })

    if (!shouldAutoUpdate) {
      setTimeout(() => {
        cleanup()
      }, 100)
    }

    return () => {
      if (cleanup) {
        cleanup()
      }
    }
  }, [target, position, shouldAutoUpdate, offsetMainAxis, offsetCrossAxis])

  React.useEffect(() => {
    const close = (event: Event) => {
      if (ModalStore.hasModalOpen()) {
        return
      }
      if (event instanceof KeyboardEvent && event.key === "Escape") {
        if (onCloseRequest && !onCloseRequest(event)) {
          return
        }
        PopoutActionCreators.close(popoutKey)
        setIsOpen(false)

        if (returnFocusRef?.current) {
          returnFocusRef.current.focus()
        }

        return
      }
      if (event instanceof MouseEvent && popoutRef.current && !popoutRef.current.contains(event.target as Node)) {
        if (onCloseRequest && !onCloseRequest(event)) {
          return
        }
        PopoutActionCreators.close(popoutKey)
        setIsOpen(false)

        if (returnFocusRef?.current) {
          returnFocusRef.current.focus()
        }

        return
      }
    }

    document.addEventListener("click", close, true)
    document.addEventListener("contextmenu", close, true)
    document.addEventListener("keydown", close, true)

    return () => {
      document.removeEventListener("click", close, true)
      document.removeEventListener("contextmenu", close, true)
      document.removeEventListener("keydown", close, true)
    }
  }, [popoutKey, onCloseRequest, returnFocusRef])

  const {isMounted, styles} = useTransitionStyles(isOpen, popoutRef.current, position as any, {
    duration: animationType === "smooth" ? 250 : 0,
    initial: {opacity: 0, transform: "scale(0.98)"},
    open: {opacity: 1, transform: "scale(1)"},
    close: {opacity: 0, transform: "scale(0.98)"},
    common: {transitionTimingFunction: "ease-in-out"},
  })

  const className = clsx(popoutStyles.popout, containerClass)

  if (!isMounted) {
    return null
  }

  return (
    <div
      ref={popoutRef}
      className={className}
      aria-modal={true}
      role="dialog"
      tabIndex={-1}
      style={{
        position: "absolute",
        zIndex: zIndexBoost != null ? 1000 + zIndexBoost : undefined,
        ...styles,
      }}
    >
      {render({popoutKey, onClose: () => PopoutActionCreators.close(popoutKey)})}
    </div>
  )
}

export const Popouts = () => {
  const {popouts} = PopoutStore.useStore()
  return (
    <div className={popoutStyles.popouts}>
      {Object.values(popouts).map((popoutData: any) => (
        <PopoutItem {...popoutData} key={`${popoutData.key}-popout`} popoutKey={popoutData.key.toString()} />
      ))}
    </div>
  )
}
