export const getSelectionText = () => {
  const selection = window.getSelection()
  return selection ? selection.toString() : ""
}

export const contextMenuCallbackWeb = (event: MouseEvent) => {
  let allow = false
  let src: string | undefined
  let href: string | undefined

  if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
    allow = true
  } else if (getSelectionText()) {
    allow = true
  } else {
    let node = event.target as HTMLElement

    do {
      if ((node as HTMLImageElement).src != null) {
        src = (node as HTMLImageElement).src
      }
      if ((node as HTMLAnchorElement).href != null) {
        href = (node as HTMLAnchorElement).href
      }

      node = node.parentNode as HTMLElement
    } while (node != null && node !== document.body)

    if (href != null || src != null) {
      allow = true
    }
  }

  if (!allow) {
    event.preventDefault()
  }
}
