import type {ToastProps} from "~/components/uikit/Toast"
import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"

type State = {
  toasts: Record<string, ToastProps>
}

const initialState: State = {
  toasts: {},
}

class ToastStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "TOAST_SET":
        return this.handleToastSet(action)
      case "TOAST_DELETE":
        return this.handleToastDelete(action)
      default:
        return false
    }
  }

  getToasts() {
    return this.state.toasts
  }

  private handleToastSet({id, data}: {id: string; data: ToastProps}) {
    this.setState((state) => {
      const toasts = {...state.toasts, [id]: data}
      return {toasts}
    })
  }

  private handleToastDelete({id}: {id: string}) {
    this.setState((state) => {
      const {[id]: _, ...toasts} = state.toasts
      return {toasts}
    })
  }
}

export default new ToastStore()
