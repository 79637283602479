import {AnimatePresence} from "motion/react"
import React from "react"
import * as ToastActionCreators from "~/actions/ToastActionCreators"
import {Toast} from "~/components/uikit/Toast/Toast"
import styles from "~/components/uikit/Toast/index.module.css"
import ToastStore from "~/stores/ToastStore"

export const Toasts = () => {
  const {toasts} = ToastStore.useStore()

  const closeToast = React.useCallback((id: string) => {
    ToastActionCreators.destroyToast(id)
  }, [])

  return (
    <div className={styles.toasts}>
      <AnimatePresence>
        {Object.entries(toasts).map(([toastId, toastProps]) => (
          <Toast key={toastId} id={toastId} closeToast={closeToast} {...toastProps} />
        ))}
      </AnimatePresence>
    </div>
  )
}
