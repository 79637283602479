import clsx from "clsx"
import {AnimatePresence, motion} from "motion/react"
import React from "react"
import type {TooltipItemProps, TooltipPosition, TooltipType} from "~/components/uikit/Tooltip"
import styles from "~/components/uikit/Tooltip/index.module.css"
import TooltipStore from "~/stores/TooltipStore"

const MAX_WIDTH_MAP = {
  default: 190,
  xl: 350,
  none: "none",
}

const TooltipPositionToStyle: Record<TooltipPosition, string> = {
  top: styles.tooltipTop,
  left: styles.tooltipLeft,
  right: styles.tooltipRight,
  bottom: styles.tooltipBottom,
}

const TooltipTypeToStyle: Record<TooltipType, string> = {
  normal: styles.tooltipPrimary,
  error: styles.tooltipRed,
}

const getPointerStyle = (position: TooltipPosition, alignment: string, nudge: number) => {
  const isHorizontal = position === "left" || position === "right"
  const alignProperty = isHorizontal ? "top" : "left"
  let alignValue = "50%"
  let offset = nudge

  if (alignment === (isHorizontal ? "top" : "left")) {
    alignValue = "0%"
    offset += 16
  } else if (alignment === (isHorizontal ? "bottom" : "right")) {
    alignValue = "100%"
    offset -= 20
  }

  return {
    [alignProperty]: `calc(${alignValue} + ${offset}px)`,
  }
}

const TooltipItem = ({
  text,
  type,
  position,
  align,
  nudge,
  x,
  y,
  targetWidth,
  targetHeight,
  padding,
  maxWidth,
}: TooltipItemProps) => {
  const [offsetX, setOffsetX] = React.useState<number | null>(null)
  const [offsetY, setOffsetY] = React.useState<number | null>(null)
  const tooltipRef = React.useRef<HTMLDivElement | null>(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useLayoutEffect(() => {
    updateOffsets()
  }, [text, position, x, y, targetWidth, targetHeight, padding])

  const updateOffsets = () => {
    const domNode = tooltipRef.current
    if (!domNode) {
      return
    }

    let newOffsetX: number
    let newOffsetY = -(domNode.offsetHeight / 2)

    switch (position) {
      case "left": {
        newOffsetX = -domNode.offsetWidth - padding
        newOffsetY += targetHeight / 2
        break
      }
      case "right": {
        newOffsetX = targetWidth + padding
        newOffsetY += targetHeight / 2
        break
      }
      case "bottom": {
        newOffsetX = (targetWidth - domNode.offsetWidth) / 2
        newOffsetY = targetHeight + padding
        break
      }
      default: {
        newOffsetX = (targetWidth - domNode.offsetWidth) / 2
        newOffsetY = -domNode.offsetHeight - padding
      }
    }

    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight
    const tooltipWidth = domNode.offsetWidth
    const tooltipHeight = domNode.offsetHeight

    if (x + newOffsetX < padding) {
      newOffsetX = padding - x
    } else if (x + newOffsetX + tooltipWidth > viewportWidth - padding) {
      newOffsetX = viewportWidth - padding - x - tooltipWidth
    }

    if (y + newOffsetY < padding) {
      newOffsetY = padding - y
    } else if (y + newOffsetY + tooltipHeight > viewportHeight - padding) {
      newOffsetY = viewportHeight - padding - y - tooltipHeight
    }

    setOffsetX(newOffsetX)
    setOffsetY(newOffsetY)
  }

  const content = typeof text === "function" ? text() : text
  if (!content || (Array.isArray(content) && content.length === 0)) {
    return null
  }

  const style = {
    position: "absolute" as const,
    left: offsetX != null ? x + offsetX : undefined,
    top: offsetY != null ? y + offsetY : undefined,
  }

  return (
    <motion.div
      ref={tooltipRef}
      style={style}
      initial={{opacity: 0, scale: 0.98}}
      animate={{opacity: 1, scale: 1}}
      exit={{opacity: 0, scale: 0.98}}
      transition={{
        opacity: {duration: 0.1},
        scale: {type: "spring", damping: 25, stiffness: 500},
      }}
    >
      <div
        className={clsx(styles.tooltip, TooltipPositionToStyle[position], TooltipTypeToStyle[type])}
        style={{maxWidth: MAX_WIDTH_MAP[maxWidth]}}
      >
        <div
          className={clsx(styles.tooltipPointer, styles.tooltipPointerBg)}
          style={getPointerStyle(position, align, nudge)}
        />
        <div className={clsx(styles.tooltipPointer)} style={getPointerStyle(position, align, nudge)} />
        <div className={clsx(styles.tooltipContent)}>{content}</div>
      </div>
    </motion.div>
  )
}

export const Tooltips = () => {
  const {tooltips} = TooltipStore.useStore()
  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
  const tooltipElements = Object.values(tooltips).map((props, index) => <TooltipItem key={index} {...props} />)
  return (
    <AnimatePresence>
      <div className={styles.tooltips}>{tooltipElements}</div>
    </AnimatePresence>
  )
}
