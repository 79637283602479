import {Outlet} from "@remix-run/react"
import {FocusGuard, LOCK_STACK} from "focus-layers"
import React from "react"
import {ThemeTypeToString} from "~/Constants"
import * as WindowActionCreators from "~/actions/WindowActionCreators"
import {Modals} from "~/components/modals/Modals"
import {Popouts} from "~/components/uikit/Popout/Popouts"
import {SVGMasks} from "~/components/uikit/SVGMasks"
import {Toasts} from "~/components/uikit/Toast/Toasts"
import {Tooltips} from "~/components/uikit/Tooltip/Tooltips"
import {NavigateListener} from "~/lib/NavigateListener"
import AccessibilityStore from "~/stores/AccessibilityStore"
import UserSettingsStore from "~/stores/UserSettingsStore"
import * as ContextMenuUtils from "~/utils/ContextMenuUtils"

export const App = () => {
  const userSettings = UserSettingsStore.useStore()
  const {saturationFactor, alwaysUnderlineLinks} = AccessibilityStore.useStore()
  const [focusLockActive, setFocusLockActive] = React.useState(false)

  React.useEffect(() => {
    if (window.ENV.VERSION_HASH) {
      console.info(`[BUILD INFO] Version Hash: ${window.ENV.VERSION_HASH}`)
    }

    const preventScroll = (event: Event) => event.preventDefault()
    const handleBlur = () => WindowActionCreators.focus(false)
    const handleFocus = () => WindowActionCreators.focus(true)
    const handleResize = () => WindowActionCreators.resized()
    const handleContextMenu = ContextMenuUtils.contextMenuCallbackWeb

    document.addEventListener("scroll", preventScroll)
    window.addEventListener("blur", handleBlur)
    window.addEventListener("focus", handleFocus)
    window.addEventListener("mousedown", handleFocus)
    window.addEventListener("resize", handleResize)
    window.addEventListener("contextmenu", handleContextMenu, false)

    return () => {
      document.removeEventListener("scroll", preventScroll)
      window.removeEventListener("blur", handleBlur)
      window.removeEventListener("focus", handleFocus)
      window.removeEventListener("mousedown", handleFocus)
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("contextmenu", handleContextMenu, false)
    }
  }, [])

  React.useEffect(() => {
    return LOCK_STACK.subscribe(setFocusLockActive)
  }, [])

  React.useEffect(() => {
    const htmlNode = document.documentElement

    htmlNode.classList.add(`theme-${ThemeTypeToString[userSettings.theme]}`)
    htmlNode.style.setProperty("--saturation-factor", saturationFactor.toString())
    if (alwaysUnderlineLinks) {
      htmlNode.style.setProperty("--link-decoration", "underline")
    } else {
      htmlNode.style.removeProperty("--link-decoration")
    }

    return () => {
      htmlNode.classList.remove(`theme-${ThemeTypeToString[userSettings.theme]}`)
      htmlNode.style.removeProperty("--saturation-factor")
      htmlNode.style.removeProperty("--link-decoration")
    }
  }, [userSettings.theme, saturationFactor, alwaysUnderlineLinks])

  React.useEffect(() => {
    let styleElement: HTMLStyleElement | null = null

    if (userSettings.custom_css) {
      styleElement = document.createElement("style")
      styleElement.textContent = userSettings.custom_css
      document.head.appendChild(styleElement)
    }

    return () => {
      if (styleElement) {
        document.head.removeChild(styleElement)
      }
    }
  }, [userSettings.custom_css])

  return (
    <>
      <NavigateListener />
      <SVGMasks />
      <FocusGuard />
      <div aria-hidden={focusLockActive}>
        <Outlet />
      </div>
      <Modals />
      <Popouts />
      <Toasts />
      <Tooltips />
      <FocusGuard />
    </>
  )
}
