import React from "react"

const useDelayUnmount = (open: boolean, durationMs: number) => {
  const [isMounted, setIsMounted] = React.useState(open)

  if (open && !isMounted) {
    setIsMounted(true)
  }

  React.useEffect(() => {
    if (!open && isMounted) {
      const timeout = setTimeout(() => setIsMounted(false), durationMs)
      return () => clearTimeout(timeout)
    }
    return
  }, [open, isMounted, durationMs])

  return isMounted
}

export type UseTransitionStatusProps = {
  duration?: number | {open?: number; close?: number}
}

type TransitionStatus = "unmounted" | "initial" | "open" | "close"

export const useTransitionStatus = (
  open: boolean,
  floating: HTMLElement | null,
  props: UseTransitionStatusProps = {},
): {
  isMounted: boolean
  status: TransitionStatus
} => {
  const {duration = 250} = props
  const isNumberDuration = typeof duration === "number"
  const closeDuration = (isNumberDuration ? duration : duration.close) || 0

  const [status, setStatus] = React.useState<TransitionStatus>("unmounted")
  const isMounted = useDelayUnmount(open, closeDuration)

  if (!isMounted && status === "close") {
    setStatus("unmounted")
  }

  React.useEffect(() => {
    if (!floating) {
      return
    }

    if (open) {
      setStatus("initial")

      const frame = requestAnimationFrame(() => {
        setStatus("open")
      })

      return () => {
        cancelAnimationFrame(frame)
      }
    }

    setStatus("close")
    return
  }, [open, floating])

  return {
    isMounted,
    status,
  }
}
