import "highlight.js/styles/github-dark.css"

import "~/tailwind.css"
import "~/i18n"

import crypto from "node:crypto"
import {Links, Meta, Scripts, isRouteErrorResponse, json, useRouteError, useRouteLoaderData} from "@remix-run/react"
import React from "react"
import invariant from "tiny-invariant"
import {App} from "~/App.client"
import {FluxerIcon} from "~/components/icons/FluxerIcon"
import {Button} from "~/components/uikit/Button/Button"
import {i18n} from "~/i18n"
import favicon from "~/images/favicon.ico"
import appleTouchIcon from "~/images/fluxer_128.png"

declare global {
  interface Window {
    ENV: {
      NODE_ENV: "production" | "development"
      API_ENDPOINT: string
      API_VERSION: number
      GATEWAY_ENDPOINT: string
      MEDIA_PROXY_ENDPOINT: string
      STATIC_ENDPOINT: string
      VERSION_HASH?: string
    }
  }
}

const CSP_HOSTS = {
  FRAME: ["https://www.youtube.com/embed/", "https://www.youtube.com/s/player/"],
  IMAGE: ["https://*.fluxer.app", "https://i.ytimg.com", "https://*.youtube.com"],
  MEDIA: ["https://*.fluxer.app", "https://*.youtube.com"],
  SCRIPT: ["https://*.fluxer.app"],
  STYLE: ["https://*.fluxer.app"],
  FONT: ["https://*.fluxer.app"],
  CONNECT: [
    "https://*.fluxer.app",
    "wss://*.fluxer.app",
    "https://0b0b521714c6316b5d8e889ef6416498.r2.cloudflarestorage.com",
  ],
  WORKER: ["https://*.fluxer.app"],
  MANIFEST: ["https://*.fluxer.app"],
}

export const loader = async () => {
  invariant(typeof process.env.API_ENDPOINT === "string", "API_ENDPOINT is not defined")
  invariant(typeof process.env.API_VERSION === "string", "API_VERSION is not defined")
  invariant(typeof process.env.GATEWAY_ENDPOINT === "string", "GATEWAY_ENDPOINT is not defined")
  invariant(typeof process.env.MEDIA_PROXY_ENDPOINT === "string", "MEDIA_PROXY_ENDPOINT is not defined")
  invariant(typeof process.env.STATIC_ENDPOINT === "string", "STATIC_ENDPOINT is not defined")

  const nonce = crypto.randomBytes(16).toString("base64")
  const headers = new Headers({
    "Content-Security-Policy": [
      `default-src 'self'`,
      `script-src 'self' ${CSP_HOSTS.SCRIPT.join(" ")} 'nonce-${nonce}'`,
      `style-src 'self' 'unsafe-inline' ${CSP_HOSTS.STYLE.join(" ")}`,
      `img-src 'self' blob: data: ${CSP_HOSTS.IMAGE.join(" ")}`,
      `media-src 'self' blob: ${CSP_HOSTS.MEDIA.join(" ")}`,
      `font-src 'self' ${CSP_HOSTS.FONT.join(" ")} data:`,
      `connect-src 'self' ${CSP_HOSTS.CONNECT.join(" ")}`,
      `frame-src 'self' ${CSP_HOSTS.FRAME.join(" ")}`,
      `worker-src 'self' ${CSP_HOSTS.WORKER.join(" ")}`,
      `manifest-src 'self' ${CSP_HOSTS.MANIFEST.join(" ")}`,
      `object-src 'none'`,
      `base-uri 'self'`,
      `frame-ancestors 'none'`,
    ].join("; "),
  })

  const data = {
    env: {
      NODE_ENV: process.env.NODE_ENV,
      API_ENDPOINT: process.env.API_ENDPOINT,
      API_VERSION: Number.parseInt(process.env.API_VERSION, 10),
      GATEWAY_ENDPOINT: process.env.GATEWAY_ENDPOINT,
      MEDIA_PROXY_ENDPOINT: process.env.MEDIA_PROXY_ENDPOINT,
      STATIC_ENDPOINT: process.env.STATIC_ENDPOINT,
      VERSION_HASH: process.env.VERSION_HASH,
    },
    nonce,
  }
  return json(data, {headers})
}

export const headers = ({loaderHeaders}: {loaderHeaders: Headers}) => {
  if (process.env.NODE_ENV === "development") {
    return {}
  }
  return {
    "Content-Security-Policy": loaderHeaders.get("Content-Security-Policy"),
  }
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <div className="flex h-screen flex-col items-center justify-center gap-3 px-4 py-8 text-center">
        <FluxerIcon className="h-24 w-24" />

        <div className="my-2 flex flex-col items-center gap-2 text-center">
          <h1 className="font-bold text-3xl">{i18n.Messages.NOT_FOUND_TITLE}</h1>
          <p className="text-text-primary-muted">{i18n.Messages.NOT_FOUND_DESCRIPTION}</p>
        </div>

        <div className="flex flex-col gap-4 md:flex-row">
          <Button
            onClick={() => {
              window.location.href = "/"
            }}
          >
            {i18n.Messages.NOT_FOUND_BUTTON}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-3 px-4 py-8 text-center">
      <FluxerIcon className="h-24 w-24" />

      <div className="my-2 flex flex-col items-center gap-2 text-center">
        <h1 className="font-bold text-3xl">{i18n.Messages.ERROR_BOUNDARY_TITLE}</h1>
        <p className="text-text-primary-muted">{i18n.Messages.ERROR_BOUNDARY_DESCRIPTION}</p>
      </div>

      <div className="flex flex-col gap-4 md:flex-row">
        <Button onClick={() => location.reload()}>{i18n.Messages.ERROR_BOUNDARY_RELOAD}</Button>
        <Button
          onClick={() => {
            localStorage.clear()
            location.reload()
          }}
          variant="danger"
        >
          {i18n.Messages.ERROR_BOUNDARY_RESET_APP}
        </Button>
      </div>
    </div>
  )
}

export const Layout = ({children}: {children: React.ReactNode}) => {
  const loaderData = useRouteLoaderData<typeof loader>("root")
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <title>Fluxer</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Fluxer is the decentralized social platform built for the future." />
        <link rel="icon" href={favicon} />
        <link rel="apple-touch-icon" sizes="128x128" href={appleTouchIcon} />
        {loaderData ? (
          <script
            nonce={loaderData.nonce}
            // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
            dangerouslySetInnerHTML={{__html: `window.ENV = ${JSON.stringify(loaderData.env)}`}}
          />
        ) : null}
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <Scripts nonce={loaderData?.nonce} />
      </body>
    </html>
  )
}

const Root = () => {
  const [isClient, setIsClient] = React.useState(false)

  React.useEffect(() => {
    setIsClient(true)
  }, [])

  return isClient ? <App /> : null
}

export default Root
