import React from "react"
import {type UseTransitionStatusProps, useTransitionStatus} from "~/components/uikit/Popout/hooks/useTransitionStatus"

type Side = "top" | "bottom" | "left" | "right"
type Placement = `${Side}-${"start" | "end" | ""}`

type CssStylesProperty = React.CSSProperties & {
  [key: string]: any
}

type UseTransitionStylesProps = UseTransitionStatusProps & {
  initial?: CssStylesProperty
  open?: CssStylesProperty
  close?: CssStylesProperty
  common?: CssStylesProperty
}

const camelCaseToKebabCase = (str: string) =>
  str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())

const execWithArgsOrReturn = <Value extends object | undefined, SidePlacement>(
  valueOrFn: Value | ((args: SidePlacement) => Value),
  args: SidePlacement,
): Value => {
  return typeof valueOrFn === "function" ? valueOrFn(args) : valueOrFn
}

export const useTransitionStyles = (
  open: boolean,
  floating: HTMLElement | null,
  placement: Placement,
  props: UseTransitionStylesProps = {},
): {
  isMounted: boolean
  styles: React.CSSProperties
} => {
  const {initial = {opacity: 0}, open: openStyles, close: closeStyles, common, duration = 250} = props

  const side = placement.split("-")[0] as Side
  const fnArgs = React.useMemo(() => ({side, placement}), [side, placement])
  const isNumberDuration = typeof duration === "number"
  const openDuration = (isNumberDuration ? duration : duration.open) || 0
  const closeDuration = (isNumberDuration ? duration : duration.close) || 0

  const [styles, setStyles] = React.useState<React.CSSProperties>(() => ({
    ...execWithArgsOrReturn(common, fnArgs),
    ...execWithArgsOrReturn(initial, fnArgs),
  }))

  const {isMounted, status} = useTransitionStatus(open, floating, {duration})

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useEffect(() => {
    const initialStyles = execWithArgsOrReturn(initial, fnArgs)
    const closeTransitionStyles = execWithArgsOrReturn(closeStyles, fnArgs)
    const commonStyles = execWithArgsOrReturn(common, fnArgs)
    const openTransitionStyles =
      execWithArgsOrReturn(openStyles, fnArgs) ||
      Object.keys(initialStyles).reduce((acc: Record<string, "">, key) => {
        acc[key] = ""
        return acc
      }, {})

    if (status === "initial") {
      setStyles((styles) => ({
        ...styles,
        transitionProperty: styles.transitionProperty,
        ...commonStyles,
        ...initialStyles,
      }))
    }

    if (status === "open") {
      setStyles({
        transitionProperty: Object.keys(openTransitionStyles).map(camelCaseToKebabCase).join(","),
        transitionDuration: `${openDuration}ms`,
        ...commonStyles,
        ...openTransitionStyles,
      })
    }

    if (status === "close") {
      const transitionStyles = closeTransitionStyles || initialStyles
      setStyles({
        transitionProperty: Object.keys(transitionStyles).map(camelCaseToKebabCase).join(","),
        transitionDuration: `${closeDuration}ms`,
        ...commonStyles,
        ...transitionStyles,
      })
    }
  }, [status, open, floating])

  return {
    isMounted,
    styles,
  }
}
